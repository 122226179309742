import React from 'react'

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  
    return (
      <div className="inline-block">
        <div className="mb-2">
          <div className="control">
              <span className='text-black dark:text-white text-2xl font-semibold pr-4'>Signup for eNews:</span>
              <input className="inline-block w-[300px] rounded-full bg-zinc-200 dark:bg-zinc-800 border-0 pl-6" ref={node => (email = node)} type="email" name="email" placeholder="Enter your email"  />
              <button className="inline-block text-base uppercase bg-sky-300 hover:bg-sky-400 dark:bg-sky-700 hover:dark:bg-sky-800 ml-2 mb-2 px-6 py-2.5 rounded-full leading-none" onClick={submit}>Submit</button>
          </div>
        </div>
        {status === "sending" && <div className='block w-3/4 mx-auto bg-sky-100 dark:bg-sky-800 p-6 rounded-3xl text-black dark:text-white'>Sending...</div>}
        {status === "error" && (
          <div className='block w-3/4 mx-auto bg-red-100 dark:bg-red-800 p-6 rounded-3xl text-black dark:text-white'
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div className='block w-3/4 mx-auto bg-sky-100 dark:bg-sky-800 p-6 rounded-3xl text-black dark:text-white'
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    );
  };

export default CustomForm;